import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import chevronRight from '../icons/chevron-right-black.svg';

function Breadcrumbs({ links }) {
  return (
    <div className="font-regular text-sm md:text-base">
      {links.map((item, index) => (
        <React.Fragment key={item.label}>
          {!item.url ? (
            <span>{item.label}</span>
          ) : (
            <Link to={item.url} className="text-accent-2 link-underline-accent">
              {item.label}
            </Link>
          )}
          {index !== links.length - 1 ? (
            <img
              className="mx-2 inline-block"
              src={chevronRight}
              alt="arrow icon"
            />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
};

export default Breadcrumbs;
